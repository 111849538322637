.projectLink{
    text-decoration: none;
    color: black;
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: box-shadow 0.1s;
  padding: 20px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid rgb(231, 230, 230);
  box-shadow: 0 0 3px rgba(154, 152, 152, 0.2); 
}

.project:hover {
    box-shadow: 0 0 5px rgba(13, 13, 13, 0.2); 
}

.title{
    text-align: center;
    margin: 0;
}

.description{
    text-align: center;
    margin: 0;
}


