.app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

hr {
  width: 80%;
}

@media (max-width: 715px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .container {
    width: 80%;
  }
}
