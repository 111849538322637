.headElement{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px;
    align-items: center;
}

.headElement>*{
    text-align: center;
}

.title{
    font-weight: bolder;
    font-size: 2.5rem;
}

#topHr{
    display: none;
}

#headerImageRight{
    margin-left: 30px;
    height: 120px;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(13, 13, 13, 0.2); 
}

#headerImageMiddle{
    display: none;
}

@media only screen and (max-width: 715px) {
    .headElement{
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .title{
        margin-top: 0;
        font-size: 1.5rem;
    }

    #topHr{
        display: block;
    }
    
    #headerImageRight{
        display: none;
    }

    #headerImageMiddle{
        display: block;
        height: 120px;
        border-radius: 5px;
        margin: auto;
    }
  }