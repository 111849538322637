.sectionElement{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px;
    align-items: center;
    margin-bottom: 40px;
}

.projectsSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
    align-items: left;
}

.title{
    text-align: left;
    font-size: 1.5rem;
}

.icon{
    font-size: 4rem;
    margin-left: 50px;
}

.iconLink{
    color: #3c3f3e;
    text-decoration: none;
}

.iconLink:hover{
    color: black;
}

#projects{
    flex-direction: column;
}

@media only screen and (max-width: 715px) {
    .title{
        text-align: center;
    }

    .sectionElement{
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .projectSection{
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .icon{
        margin-left: 0;
    }

    #cookingIcon{
        margin: 30px;
    }
}